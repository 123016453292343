/* selfTest.module.css */

.loader {
  position: relative;
  width: 2.5em;
  height: 2.5em;
  transform: rotate(165deg);
}

.loader::before,
.loader::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.25em;
  transform: translate(-50%, -50%);
}

.loader::before {
  animation: before 2s infinite;
}

.loader::after {
  animation: after 2s infinite;
}

@keyframes before {
  0% {
    width: 0.5em;
    box-shadow: 1em -0.75em #ffa8bb, -1em 0.75em #b35262; /* Darkened shades of #F8E6EA and #E4CDD3 */
  }
  35% {
    width: 2.5em;
    box-shadow: 0 -0.75em #ffa8bb, 0 0.75em #b35262;
  }
  70% {
    width: 0.5em;
    box-shadow: -1em -0.75em #ffa8bb, 1em 0.75em #b35262;
  }
  100% {
    box-shadow: 1em -0.75em #ffa8bb, -1em 0.75em #b35262;
  }
}

@keyframes after {
  0% {
    height: 0.5em;
    box-shadow: 0.75em 1em #9ff1ff, -0.75em -1em #2aa5bb; /* Darkened shades of #E8F4F6 and #C5DCE0 */
  }
  35% {
    height: 2.5em;
    box-shadow: 0.75em 0 #9ff1ff, -0.75em 0 #2aa5bb;
  }
  70% {
    height: 0.5em;
    box-shadow: 0.75em -1em #9ff1ff, -0.75em 1em #2aa5bb;
  }
  100% {
    box-shadow: 0.75em 1em #9ff1ff, -0.75em -1em #2aa5bb;
  }
}

html,
body {
  height: 100%;
}

.loader {
  position: absolute;
  top: calc(50% - 1.25em);
  left: calc(50% - 1.25em);
}