.outer {
  min-height: fit-content;
  overflow-y: scroll;
  scrollbar-width: none;
}

.container {
    display: flex;
    flex-direction: column;
    
    border-radius: 12px;
    background: #F8E6EA;
    
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);  
    margin:1%;
    margin-left: 5%;
    margin-right: 5%;
    padding: 2%;
    justify-content: flex-start;
  }
  .changeImageText {
    margin-top: 1vw;
    font-size: 1vw;
    color: #555;
    text-align: center;
  }
  .boxfooter{
    margin-top: 0%;
    margin-left: 1.5%;
    display: flex
;
  }
  .heading {
    font-size: 2.5vw;
    color: #333;
    margin-bottom: 1vw;
  }
  
  .uploadBox {
    width: 55%;
    height: 32vh;
    border: 2px dashed #aaa;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    position: relative;
    margin-left: 1.5%;
    margin-top: 0.5%;
  }
  
  .fileInput {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
  }
  
  .preview {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
  }
  
  .note {
    font-size: 1.2vw;
    color: #444;
    margin-bottom: 1vw;
    font-weight: 500;
  }
  
  .instructions {
    list-style-type: disc;
    margin: 0vw 1vw 1.5vw 2.5vw;
    padding: 0;
    font-size: 1.05vw;
    color: #666;
    font-weight: 500;


  }
  
  .submitButton {
    padding: 0.8vw 1vw;
    font-size: 1.5vw;
    color: #fff;
    border-radius: 8px;
    background: #150F41;
    border: none;
    cursor: pointer;
    width:20%;
    transition: background-color 0.3s ease;
  }
  
 
  .btnclass{
    width: 100%;
    display: flex;
    /* align-items: flex-end; */
    justify-content: flex-end;
  }
  .resultDiv {
    /* margin-top: 20px; */
    padding: 15px;
    background: #F8E6EA;
    text-align: center;
    padding: 2%;
    /* margin-top:2%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%; */
    margin: 2% 5% 5% 5%;
    border-radius: 12px;
    background: #F8E6EA;
    
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);  
    display: flex;
    flex-direction: column;
  }
  
  .resultHeading {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 2.5vw;
    color: #333;
  }
  
  .resultText {
    font-size: 1.5vw;
    color: #4caf50;
    font-weight: bold;
  }
  .description {
    margin-top: 20px;
    padding: 10px;
    /* border: 1px solid #ddd; */
    /* background-color: #f2f2f2; */
    /* border-radius: 8px; */
    text-align: left;
  }
  
  .descriptionHeading {
    font-size: 1.5vw;
    color: #333;
    margin-bottom: 10px;
    font-weight: 500;
  }
  
  .description p {
    font-size: 1.2vw;
    color: #555;
    line-height: 1.5;
  }
  @media (max-width: 780px) {
    .container{
      padding: 5%;
    }
    /* Increase font sizes */
    .changeImageText {
      font-size: 4vw;  /* Increase font size */
    }
  
    .heading {
      font-size: 6vw;  /* Increase heading size */
    }
  
    .note {
      font-size: 4vw;  /* Increase note size */
      margin-bottom: 4vw;

    }
  
    .instructions {
      font-size: 3.5vw;  /* Increase instructions size */
      margin-bottom: 8%;
    }
  
    .submitButton {
      font-size: 5vw;  /* Increase button font size */
      width: 50%;  /* Make the button larger */
      padding: 2vw 3vw;  /* Adjust padding */
    }
  
    .uploadBox {
      width: 80%;  /* Center the upload box and make it wider */
      height: 40vh;  /* Make the upload box taller */
      margin-left: auto;
      margin-right: auto;  /* Center horizontally */
    }
    .btnclass{
      margin-top: 10%;
    }

    .resultDiv {
      margin-top: 6%;
      padding: 5%;
    }

    .resultHeading {
      font-size: 6vw;  /* Increase result heading size */
    }
  
    .resultText {
      font-size: 5vw;  /* Increase result text size */
    }
  
    .descriptionHeading {
      font-size: 4vw;  /* Increase description heading size */
    }
  
    .description p {
      font-size: 3.5vw;  /* Increase paragraph size */
    }
  }
  