.slidercon {
  display: none;
}
.navbar {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0%;
  width: 100%;
  padding: 10px 50px;
}

.logoImage {
  width: 75%; /* Adjust size as needed */
  height: auto;
  margin-right: 10px;
}

.logoText {
  font-size: 24px;
  font-weight: bold;
  color: #3e5a7f; /* Dark blue color */
}

.navLinksfirst {
  list-style: none;
  display: flex;
  gap: 100px;
}

.navLinksfirst li a {
  text-decoration: none;
  color: #161616; /* Dark blue color */
  font-size: 1.15rem;
}

.navLinksfirst li a:hover {
  color: #210cc4; /* Change color on hover */
}
.container {
  display: flex;
  flex-direction: column;
}

.sec {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  border: 1px solid black;
}
.sec1 {
  width: 70%;
  background: #f8e6ea;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
}
.leftsec {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 10%;
}
.progressContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%; /* Adjust width as needed */
  padding: 10px;
  margin-top: 3.5%;
}

.progressBar {
  position: relative;
  width: 30%; /* Adjust width as needed */
  height: 8px;
  background-color: #c4bcbc; /* Light gray color for background */
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}

.fill {
  height: 100%;
  width: 0%; /* Start width at 0% */
  transition: width 0.1s linear; /* Smooth increase */
  background-color: transparent;
}

.blue {
  background-color: #1c1b3a; /* Dark blue color for active fill */
}


.sec2 {
  width: 30%;
  background: #221e42;
  position: relative;
}
.userOptions {
  display: flex;
  align-items: center;
  gap: 25px; /* Space between select and user icon */
  padding-top: 10px;
  position: absolute;
  right: 0%;
  padding-right: 50px;
}

.selectGender select {
  padding: 5px 10px;
  border: 2px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #221e42;
}

.userIconContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.userIcon {
  width: 30px; /* Adjust the size of the icon */
  height: 30px;
  border-radius: 50%; /* Makes the icon circular if needed */
}


.head1 {
  color: #3f3939;

  font-family: Inter;
  font-size: 3.7vw;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 88px */
  letter-spacing: 2.4px;
  max-width: 45%;
  text-align: left;
}
.head2 {
  color: #3f3939;

  text-align: justify;
  font-family: Inter;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 550;
  line-height: 105%; /* 29.76px */
  letter-spacing: 1.2px;
  max-width: 60%;
  margin-top: 3.5%;
  margin-bottom: 3%;
}
.btn,
.skinTestBtn {
  display: flex;
  width: fit-content;
  padding: 19px 20px;
  justify-content: center;
  align-items: center;
  gap: 9.644px;
  border-radius: 12px;
  background: #221e42;
  color: var(--Primary-Base-White, #fff);

  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 23.147px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.147px;
  cursor: pointer;

}
.middle {
  height: 100vh;
}
.head3 {
  color: #1e1e1e;

  text-align: center;
  font-family: Inter;
  font-size: 2.8vw;
  font-style: normal;
  font-weight: 600;
  line-height: 44px; /* 97.778% */
  letter-spacing: 0.9px;
  display: flex;
  height: 93px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  margin-top: 1.5%;
}
.head4 {
  color: #1e1e1e;

  text-align: center;
  font-family: Inter;
  font-size: 1.38vw;
  font-style: normal;
  font-weight: 600;
  line-height: 44px; /* 169.231% */
  letter-spacing: 0.52px;
}
.head5 {
  color: #1e1e1e;

  text-align: center;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.56px;
  padding-left: 10%;
  padding-right: 10%;
  line-height: 2rem;
}
.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
}
.btn2 {
  display: flex;
  height: fit-content;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid black;
  text-align: center;
  font-family: Inter;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0.56px;
  text-transform: uppercase;
  padding: 0.5vw 2vw;
  cursor: pointer;
}
.active {
  background-color: #221e42;
  color: #f3f1f1;
}

.con {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 20px;
  padding: 20px;
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
}
.conditionGrid {
  display: grid;
  gap: 20px;
  padding: 20px;
}

.conditionCard {
  background-color: #f9e4e4;
  border-radius: 20px;
  text-align: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.conditionImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.conditionTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.conditionText {
  font-size: 14px;
  color: #666;
}
.conditionBox {
  border-radius: 15px;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f8e6ea;
  /* height: 80%; */
  margin-bottom: 15%;
  /* border: 1px solid black; */
}

.image,
.img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
  margin-top: -18%;
}
.img {
  margin-top: -25%;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.description {
  font-size: 1rem;
  color: #666;
}
.skinAnalysisContainer {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  flex-direction: row;
  margin-left: 6%;
  margin-right: 6%;
  border-radius: 12px;
  background: #f8e6ea;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
}

.phoneImage {
  position: relative;
  margin-left: 5%;
}

.analysisDetails {
  width: 50%;
  margin-left: 15%;
  text-align: center;
  height: fit-content;
}

.analysisDetails h2 {
  margin-bottom: 10%;
  color: var(--Black, #000);

  text-align: left;
  font-family: Inter;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 520;
  line-height: normal;
}

.analysisGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10%;
}

.analysisItem,
.analysisitem {
  display: flex;
  align-items: center;
  color: #333;

  text-align: center;
  font-family: Inter;
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  gap: 10px;
  margin-bottom: 10%;
}

.numberCircle {
  background-color: #fff;
  border: 2px solid black;
  border-radius: 50%;
  width: 14%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: bold;
  background-color: #f8e6ea;
}
.skinTestBtn {
  width: auto;
}
.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 equal columns */
  gap: 20px; /* Space between the grid items */
  padding: 20px;
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
}
.container2 {
  padding: 20px;
  margin-left: 5%;
  margin-right: 5%;
}

.sectionTitle {
  margin-bottom: 3%;
  color: var(--Black, #000);
  text-align: center;
  font-family: Inter;
  font-size: 3vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 4%;
}

.cardContainer,
.featureContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
}

.card,
.featureCard {
  padding: 20px;
  flex: 1;
  text-align: center;
  border-radius: 8px;
  background: #f8e6ea;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.icon {
  width: 50px;
  margin-bottom: 10px;
  height: 50px;
}

.cardTitle,
.featureTitle {
  margin-bottom: 10px;
  color: #1e1e1e;

  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}

.cardDescription,
.featureDescription {
  margin-bottom: 20px;
  color: var(--Black-500, #202020);
  font-family: Inter;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  letter-spacing: 0.4px;
  text-align: left;
}
.featureDescription {
  height: 45%;
  letter-spacing: 0.36px;
}

.button3 {
  z-index: 15;
  background-color: #221e42;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  width: 95%;
  margin-top: 0;
}
/* footer css starting...................................... */

.footer {
  background-color: #1e1f26;
  color: #ffffff;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
}

.footcontainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  /* max-width: 1200px; */
  margin: 0 5%;
}

.leftSection {
  flex: 1;
  min-width: 25%;
  max-width: 30%;
  margin-right: 20px;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

.description {
  font-size: 14px;
  line-height: 1.6;
  text-align: left;
}
.description2 {
  text-align: left;
  margin-top: 15px;
  letter-spacing: 0.1vw;
}

.socialIcons {
  margin-top: 15px;
  display: flex;
}

.icon img {
  width: 24px;
  margin-right: 10px;
}

.middleSection {
  flex: 1;

  min-width: 25%;
  max-width: 30%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 300px;

  /* margin-top: 10%; */
}

.navLinks{
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 50px;
}

.navLinks li {
  margin-bottom: 25%;
}

.navLinks a {
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 39.114px;
}

.navLinks a:hover {
  text-decoration: underline;
}

.rightSection {
  min-width: 30%;
  display: flex;
  flex-direction: column;
  /* background-color: #e69500; */
}

.subscribeTitle {
  font-size: 16px;
  margin-bottom: 10px;
}

.subscribeForm {
  display: flex;
  flex-direction: column;
}

.emailInput {
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
}

.subscribeButton {
  padding: 10px;
  /* background-color: #ffa500; */
  color: #ffffff;
  border: 1.5px solid white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2vw;
}

.subscribeButton:hover {
  background-color: #e69500;
}

.copyr {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #b3b3b3;
}
.mobilecopyr {
  display: none;
}
/* footer css ending...................................... */
.imagewrap {
  position: absolute;
  top: 100%; 
  left: 75%; 
  transform: translate(-50%, -100%); 
  width: 47%; 
  height: auto;
  pointer-events: none;
}

.intersectionImage {
  width: 100%;
  height: auto;
  position: relative; /* Relative positioning within the wrapper */
  z-index: 1;
}
.maleIntersectionImage{
  width: 55%;
  height: auto;
  position: relative; /* Relative positioning within the wrapper */
  z-index: 1;
  transform: scale(1) translateX(20%); /* Adjust the translateX value to move it left */

}
.skintone {
  top: 88%;
  left: 33%;
  transform: translate(-40%, -100%);
  width: 38%;
  position: absolute;
  border-radius: 36%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 70%;
  z-index: 2;
  display: none;
}

.vectorsec {
  display: flex;
  flex-direction: column;
  gap: 45px;
  top: 100%;
  left: 60%;
  position: absolute;
  transform: translate(-10%, -105%);
}
.vectorsec2 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  top: 100%;
  left: -80%;
  position: absolute;
  transform: translate(-10%, -105%);
}
.vector {
  width: 100%;
  height: auto;
  cursor: pointer;
  z-index: 10 !important;
  /* border: 2px solid white; */
}
.imageWrapper,
.imageWrapper2 {
  position: relative; /* To allow absolute positioning of the label */
  width: 100%;
  /* Inherit the same width as your image */
}
.imageWrapper2 {
  width: 85%;
}
.label,
.labelc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
  color: rgb(19, 18, 18); /* Adjust color as needed */
  font-size: 0.86vw; /* Adjust size as needed */
  font-weight: bold;
  text-align: center;
}
.labelc {
  transform: translate(-40%, -50%);

  width: 100%;
}
.darkcircle1 {
  position: absolute;
  top: 64%;
  left: 22%;
  transform: translate(-40%, -100%);
  z-index: 2;
  width: 20%;
  display: none;
}
.line1 {
  position: absolute;
  top: 30%;
  left: 60%;
  transform: translate(-40%, -100%);
  z-index: 2;
  width: 35%;
  display: none;
}
.line2 {
  position: absolute;
  top: 45%;
  left: 65%;
  transform: translate(-40%, -100%);
  z-index: 2;
  width: 45%;
  display: none;
}
.skintypeline {
  position: absolute;
  top: 57%;
  left: 60%;
  transform: translate(-40%, -100%);
  z-index: 2;
  width: 35%;
  display: none;
}
.darkcircleline {
  position: absolute;
  top: 67%;
  left: 60%;
  transform: translate(-40%, -100%);
  z-index: 2;
  width: 35%;
  display: none;
}
.wrinkleline {
  position: absolute;
  top: 80%;
  left: 60%;
  transform: translate(-40%, -100%);
  z-index: 2;
  width: 35%;
  display: none;
}
.rightarrow1 {
  position: absolute;
  top: 80%;
  left: 20%;
  transform: translate(-40%, -100%);
  z-index: 1;
  width: 50%;
  display: none;
}
.rightarrow2 {
  position: absolute;
  top: 95%;
  left: 20%;
  transform: translate(-40%, -100%);
  z-index: 1;
  width: 35%;
  display: none;
}
.faceshapeline {
  position: absolute;
  top: 95%;
  left: 52%;
  transform: translate(-40%, -100%);
  z-index: 2;
  width: 40%;
  display: none;
}
.darkcircle2 {
  position: absolute;
  top: 64%;
  left: 48%;
  transform: translate(-40%, -100%);
  z-index: 2;
  width: 20%;
  display: none;
}
.skintype,
.eye1,
.eye2,
.firstH,
.secondH,
.thirdH,
.vertical,
.Wrinkle1,
.Wrinkle2,
.Wrinkle3,
.Wrinkle4,
.fvacne,
.fvmelasma,
.fvvitiligo,
.fvclear,
.fvmilia,
.fvrocasea {
  position: absolute;
  z-index: 2;
  display: none;
}
.skintype {
  top: 75%;
  left: 32%;
  transform: translate(-40%, -100%);
  width: 40%;
}
.fvacne {
  top: 36%;
  left: 42%;
  transform: translate(-40%, -100%);
  width: 15%;
}
.fvmelasma {
  top: 45%;
  left: 47%;
  transform: translate(-40%, -100%);
  width: 15%;
}
.fvmilia {
  top: 70%;
  left: 47%;
  transform: translate(-40%, -100%);
  width: 15%;
}
.fvvitiligo {
  top: 76%;
  left: 47%;
  transform: translate(-40%, -100%);
  width: 15%;
}
.fvrocasea {
  top: 70%;
  left: 47%;
  transform: translate(-40%, -100%);
  width: 15%;
}
.fvclear {
  top: 95%;
  left: 40%;
  transform: translate(-40%, -100%);
  width: 15%;
}
.eye1 {
  top: 56%;
  left: 47%;
  transform: translate(-40%, -100%);
  width: 20%;
}
.eye2 {
  top: 56%;
  left: 21%;
  transform: translate(-40%, -100%);
  width: 20%;
}
.firstH {
  top: 35%;
  left: 33%;
  transform: translate(-40%, -100%);
  width: 37%;
}
.secondH {
  top: 55%;
  left: 32%;
  transform: translate(-40%, -100%);
  width: 47%;
}
.thirdH {
  top: 70%;
  left: 33%;
  transform: translate(-40%, -100%);
  width: 37%;
}
.vertical {
  top: 88%;
  left: 37%;
  transform: translate(-40%, -100%);
  height: 68%;
}
.Wrinkle1 {
  top: 75%;
  left: 26%;
  transform: translate(-40%, -100%);
  width: 10%;
}
.Wrinkle2 {
  top: 77%;
  left: 47%;
  transform: translate(-40%, -100%);
  width: 10%;
}
.Wrinkle3 {
  top: 83%;
  left: 28%;
  transform: translate(-40%, -100%);
  width: 10%;
}
.Wrinkle4 {
  top: 83%;
  left: 43%;
  transform: translate(-40%, -100%);
  width: 10%;
}
.mobcontainer2 {
  display: none;
}
@media screen and (max-width: 1440px) {
  .imagewrap {
    position: absolute; /* Position the entire wrapper absolutely */
    top: 100%; /* Place the wrapper at 100% from the top of the .sec container */
    left: 77%; /* Offset it from the left */
    transform: translate(-50%, -87%); /* Translate to center */
    width: 47%; /* Adjust width to your needs */
    height: auto;
    pointer-events: none;
  }
  .line1 {
    position: absolute;
    top: 23%;
    left: 57%;
    transform: translate(-40%, -100%);
    z-index: 2;
    width: 37%;
    display: none;
  }
  .line2 {
    position: absolute;
    top: 40%;
    left: 65%;
    transform: translate(-40%, -100%);
    z-index: 2;
    width: 45%;
    display: none;
  }
  .skintypeline {
    position: absolute;
    top: 54%;
    left: 62%;
    transform: translate(-40%, -100%);
    z-index: 2;
    width: 37%;
    display: none;
  }
  .fvacne {
    top: 30%;
    left: 39%;
    transform: translate(-40%, -100%);
    width: 15%;
  }
  .navLinks a {
    color: #fff;
    font-family: Inter;
    font-size: 18px;
  }
}
@media screen and (max-width: 916px) {
  .navbar{
    display: none;
  }
  .sec {
    width: 100%;
    height: 40vh;
  }
  .sec1 {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: top;
  }
  .leftsec {
    justify-content: center;
    align-items: left;
  }
  .sec2 {
    display: none;
  }
  .head1 {
    font-size: 4.2vw;
    max-width: 90%;
  }
  .head2 {
    font-size: 2.5vw;
    max-width: 75%;
    margin-top: 8%;
    line-height: 95%; /* 29.76px */
    letter-spacing: 0.2px;
  }
  .btn,
  .skinTestBtn {
    font-size: 3vw;
    margin-top: 4%;
    padding: 12px 10px;
    cursor: pointer;

  }

  .imagewrap {
    display: none;
  }

  .head3 {
    font-size: 1.5rem;
    margin-top: 0%;
    letter-spacing: 0.44px;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    margin-left: 5%;
  }
  .head4 {
    display: none;
  }
  .head5 {
    color: #1e1e1e;
    text-align: justify;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 200% */
    letter-spacing: 0.28px;
    padding-left: 0%;
    padding-right: 5%;
    margin-left: 5%;
  }
  .button {
    margin-top: 5%;
  }
  .btn2 {
    font-size: 2.5vw;
  }
  .con {
    display: none;
  }
  .gridContainer {
    display: none;
  }
  .conditionGrid {
    display: grid;
    gap: 20px;
    padding: 20px;
  }

  .conditionCard {
    background-color: #f9e4e4;
    border-radius: 20px;
    text-align: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .conditionImage {
    width: 53.07px;
    height: 55px;
    flex-shrink: 0;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 2%;
    margin-top: -12%;
  }

  .conditionTitle {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 2%;
  }

  .conditionText {
    color: #1e1e1e;
    text-align: center;
    font-family: Inter;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 15px; /* 122.222% */
    letter-spacing: 0.09px;
  }
  .slidercon {
    display: block;
    box-sizing: border-box;
    padding-top: 7%;
    padding-bottom: 10%;
  }
  .slick-dots {
    bottom: -30px;
  }

  .slick-dots li button:before {
    font-size: 15px;
    color: #000;
  }

  .slick-dots li.slick-active button:before {
    color: #000;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #000;
  }
  .skinAnalysisContainer {
    flex-direction: column;
  }
  .analysisDetails {
    width: 100%;
    /* margin-left: 15%; */
    text-align: center;
    height: fit-content;
  }
  .analysisDetails h2 {
    margin-bottom: 5%;
    margin-top: 5%;
    color: var(--Black, #000);

    font-family: Inter;
    font-size: 4vw;
    font-style: normal;
    font-weight: 520;
    line-height: normal;
  }
  .analysisItem,
  .analysisitem {
    font-size: 3vw;

    margin-bottom: 10%;
  }

  .numberCircle {
    width: 12%;
  }
  .mobcontainer2 {
    display: block;
    padding: 20px;
    margin-left: 5%;
    margin-right: 5%;
  }
  .container2 {
    display: none;
  }
  .sectionTitle,
  .sectionTitle2 {
    margin-bottom: 3%;
    color: var(--Black, #000);
    text-align: center;
    font-family: Inter;
    font-size: 5vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 4%;
  }
  .sectionTitle2 {
    margin-top: 7%;
  }
  .cardContainer,
  .featureContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .card,
  .featureCard {
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    background: #f8e6ea;

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3%;
  }

  .icon {
    width: 80px;
    margin-bottom: 10px;
    height: 80px;
  }

  .cardTitle,
  .featureTitle {
    margin-bottom: 10px;
    color: #1e1e1e;

    font-family: Inter;
    font-size: 3vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
  }

  .cardDescription,
  .featureDescription {
    margin-bottom: 10px;
    color: var(--Black-500, #202020);
    font-family: Inter;
    font-size: 2vw;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 120% */
    letter-spacing: 0.4px;
    text-align: justify;
  }
  .featureDescription {
    letter-spacing: 0.36px;
  }
  .card,
  .featureCard {
    padding: 20px;
    display: flex;
    height: 255px;
    text-align: center;
    border-radius: 15px;

    box-shadow: none;
  }

  .featureCard {
    height: 330px;
  }
  .button3 {
    background-color: #221e42;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    width: 95%;
    margin-top: 0%;
    margin-bottom: 10px;
  }
  .footer {
    margin-top: 5%;
  }
  .footcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* max-width: 1200px; */
    margin: 0%;
    flex-direction: column;
    width: 100%;
  }
  .leftSection {
    min-width: 80%;
    max-width: 80%;
    margin-right: 0px;
  }
  .middleSection {
    min-width: 80%;
    max-width: 80%;
    height: 100px;
  }
  .copyr {
    display: none;
  }
  .rightSection {
    min-width: 80%;
    text-align: left;
    align-items: flex-start;
  }
  .navLinks {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 35px;
  }

  .navLinks li {
    margin-bottom: 15%;
  }

  .navLinks a {
    color: #fff;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 39.114px;
  }
  .subscribeButton {
    font-size: 3vw;
    width: 60%;
  }
  .mobilecopyr {
    display: block;
    margin-top: 5%;
    font-size: 14px;
    color: #b3b3b3;
  }
  .subscribeTitle {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .subscribeForm {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .emailInput {
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
  }

}
@media screen and (max-width: 768px) {
  
  .sec {
    width: 100%;
    height: 45vh;
  }
  .sec1 {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: top;
  }
  .leftsec {
    margin-top: 8%;
    justify-content: center;
    align-items: left;
  }
  .sec2 {
    display: none;
  }
  .head1 {
    font-size: 6vw;
    max-width: 90%;
  }
  .head2 {
    font-size: 3.2vw;
    max-width: 75%;
    margin-top: 8%;
    line-height: 95%; /* 29.76px */
    letter-spacing: 0.2px;
  }
  .btn,
  .skinTestBtn {
    font-size: 4vw;
    margin-top: 4%;
    padding: 12px 10px;
  }

  .imagewrap {
    display: none;
  }

  .head3 {
    font-size: 1.5rem;
    margin-top: 0%;
    letter-spacing: 0.44px;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    margin-left: 5%;
  }
  .head4 {
    display: none;
  }
  .head5 {
    color: #1e1e1e;
    text-align: justify;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 200% */
    letter-spacing: 0.28px;
    padding-left: 0%;
    padding-right: 5%;
    margin-left: 5%;
  }
  .button {
    margin-top: 5%;
  }
  .btn2 {
    font-size: 4.5vw;
  }
  .con {
    display: none;
  }
  .gridContainer {
    display: none;
  }
  .conditionGrid {
    display: grid;
    gap: 20px;
    padding: 20px;
  }

  .conditionCard {
    background-color: #f9e4e4;
    border-radius: 20px;
    text-align: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 225px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .conditionImage {
    width: 53.07px;
    height: 55px;
    flex-shrink: 0;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 2%;
    margin-top: -30%;
  }

  .conditionTitle {
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 2%;
  }

  .conditionText {
    color: #1e1e1e;
    text-align: center;
    font-family: Inter;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px; /* 122.222% */
    letter-spacing: 0.09px;
  }
  .slidercon {
    display: block;
    box-sizing: border-box;
    padding-top: 5%;
    padding-bottom: 10%;
  }
  .slick-dots {
    bottom: -30px;
  }

  .slick-dots li button:before {
    font-size: 12px;
    color: #000;
  }

  .slick-dots li.slick-active button:before {
    color: #000;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #000;
  }
  .skinAnalysisContainer {
    flex-direction: column;
  }
  .analysisDetails {
    width: 100%;
    /* margin-left: 15%; */
    text-align: center;
    height: fit-content;
  }
  .analysisDetails h2 {
    margin-bottom: 5%;
    margin-top: 2%;
    color: var(--Black, #000);

    font-family: Inter;
    font-size: 4vw;
    font-style: normal;
    font-weight: 520;
    line-height: normal;
  }
  .analysisItem,
  .analysisitem {
    font-size: 3vw;

    margin-bottom: 10%;
  }

  .numberCircle {
    width: 13%;
  }
  .mobcontainer2 {
    display: block;
    padding: 20px;
    margin-left: 5%;
    margin-right: 5%;
  }
  .container2 {
    display: none;
  }
  .sectionTitle,
  .sectionTitle2 {
    margin-bottom: 3%;
    color: var(--Black, #000);
    text-align: center;
    font-family: Inter;
    font-size: 5vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 4%;
  }
  .sectionTitle2 {
    margin-top: 10%;
  }
  .cardContainer,
  .featureContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .card,
  .featureCard {
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    background: #f8e6ea;

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .icon {
    width: 80px;
    margin-bottom: 10px;
    height: 80px;
  }

  .cardTitle,
  .featureTitle {
    margin-bottom: 10px;
    color: #1e1e1e;

    font-family: Inter;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
  }

  .cardDescription,
  .featureDescription {
    margin-bottom: 10px;
    color: var(--Black-500, #202020);
    font-family: Inter;
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 120% */
    letter-spacing: 0.4px;
    text-align: justify;
  }
  .featureDescription {
    letter-spacing: 0.36px;
  }
  .card,
  .featureCard {
    padding: 20px;
    display: flex;
    height: 250px;
    text-align: center;
    border-radius: 15px;

    box-shadow: none;
  }

  .featureCard {
    height: 330px;
  }
  .button3 {
    background-color: #221e42;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    width: 95%;
    margin-top: 0%;
    margin-bottom: 10px;
  }
/* ..................footer css.......................................... */
  .footer {
    margin-top: 5%;
  }
  .footcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* max-width: 1200px; */
    margin: 0%;
    flex-direction: column;
    width: 100%;
  }
  .leftSection {
    min-width: 100%;
    max-width: 100%;
    margin-right: 0px;
  }
  .middleSection {
    min-width: 100%;
    max-width: 100%;
    height: 100px;
  }
  .copyr {
    display: none;
  }
  .rightSection {
    min-width: 100%;
  }
  .navLinks {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 35px;
  }

  .navLinks li {
    margin-bottom: 15%;
  }

  .navLinks a {
    color: #fff;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 39.114px;
  }
  .subscribeButton {
    font-size: 5vw;
  }
  .mobilecopyr {
    display: block;
    margin-top: 5%;
    font-size: 14px;
    color: #b3b3b3;
  }
  /* footer css ending.......................................... */

  .navbar {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0%;
    width: 100%;
    padding: 10px;
  }
  
  .logoImage {
    width: 50%; /* Adjust size as needed */
    height: auto;
    margin-right: 0px;
  }
  
  .logoText {
    font-size: 24px;
    font-weight: bold;
    color: #3e5a7f; /* Dark blue color */
  }
  
  .navLinksfirst {
    list-style: none;
    display: flex;
    gap: 15px;
  }
  
  .navLinksfirst li a {
    text-decoration: none;
    color: #161616; /* Dark blue color */
    font-size: 0.9rem;
  }
  
  .navLinksfirst li a:hover {
    color: #210cc4; /* Change color on hover */
  }
  .navbar{
    display: none;
  }
}
