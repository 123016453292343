.outer {
  /* height:100vh; */
  display: flex;
  flex-direction: column;
}
.first {
  height: 15%;
  background-color: #f44336;
  width: 100%;
  margin: 0%;
  border-radius: 12px 12px 0px 0px;
  background: linear-gradient(90deg, #ffd3dd 0%, #c0eff7 100%);
}
.profileContainer {
  width: 90%;
  margin: 0 auto;
  background: #f4f0f0;
  border-radius: 10px;
  border-radius: 0px 0px 12px 12px;
  background: #f4f0f0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 1.5%;
  height: 80vh;
}

.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
  padding: 2% 5%;
  padding-bottom: 0%;
}

.profileImg {
  border-radius: 50%;
  width: 7%; /* Keep as percentage for responsiveness */
  height: auto;
}

.profileInfo {
  flex: 1;
  margin-left: 1.5%;
}
.head1 {
  color: var(--Black, #000);
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 550;
  line-height: normal;
}
.head2 {
  color: var(--Black, #000);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
  margin-top: 1%;
}

.editBtn {
  background: #221e42;
  color: white;
  padding: 1% 2%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 10%;
}

.profileForm {
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
  padding: 2% 5%;
  padding-top: 0%;
}

.formGroup {
  flex: 1 1 45%; /* Each form group takes 45% width of the container */
  margin-bottom: 2%;
}
.formGroup label {
  display: block;
  font-size: 1.3rem; /* Slightly larger than normal text */
  margin-bottom: 0.5rem; /* Add spacing below the label */
  color: var(--Black, #000);
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal; /* Darker color for better contrast */
}

/* Styling for static text (p) */
.formGroup p {
  font-size: 1rem; /* Adjust font size */
  color: #666; /* Subtle text color */
  padding: 2%; /* Add padding for better spacing */
  background: #f9f9f9; /* Light background to differentiate from input */
  border-radius: 5px; /* Rounded corners */
  border: 1px solid #ddd; /* Light border for text block */
  height: 55%;
}
.formGroup input {
  width: 95%; /* Full width input fields */
  padding: 2%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 2%;
}
.formGroup select {
  width: 95%; /* Full width input fields */
  padding: 2%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 2%;
}

.logoutBtn {
  background: #f4f0f0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 17%; /* Keep logout button width percentage */
  margin-top: 3.5%;
  display: flex;
  flex-direction: row;
  gap: 5%;
  justify-content: center;
  color: #53555a;
  font-weight: 500;
}
.footer {
  display: none;
}
.footer {
  background-color: #1e1f26;
  color: #ffffff;
  padding: 40px 20px;
  margin-top: 3%;
  font-family: Arial, sans-serif;
}

.footcontainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  /* max-width: 1200px; */
  margin: 0 5%;
}

.leftSection {
  flex: 1;
  min-width: 25%;
  max-width: 30%;
  margin-right: 20px;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

.description {
  font-size: 14px;
  line-height: 1.6;
  text-align: left;
}
.description2 {
  text-align: left;
  margin-top: 15px;
  letter-spacing: 0.1vw;
}
.textdiv {
  display: flex;
  justify-content: flex-start;
  border: 1 px solid black;
}
.socialIcons {
  margin-top: 15px;
  display: flex;
}

.icon img {
  width: 24px;
  margin-right: 10px;
}

.middleSection {
  flex: 1;

  min-width: 25%;
  max-width: 30%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 300px;

  /* margin-top: 10%; */
}

.navLinks {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 50px;
}

.navLinks li {
  margin-bottom: 25%;
}

.navLinks a {
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 39.114px;
}

.navLinks a:hover {
  text-decoration: underline;
}

.rightSection {
  min-width: 30%;
  display: flex;
  flex-direction: column;
  /* background-color: #e69500; */
}

.subscribeTitle {
  font-size: 16px;
  margin-bottom: 10px;
}

.subscribeForm {
  display: flex;
  flex-direction: column;
}

.emailInput {
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
}

.subscribeButton {
  padding: 10px;
  /* background-color: #ffa500; */
  color: #ffffff;
  border: 1.5px solid white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2vw;
}

.subscribeButton:hover {
  background-color: #e69500;
}

.copyr {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #b3b3b3;
}
.mobilecopyr {
  display: none;
}
.buttonlast {
  width: 100%;
}
@media screen and (max-width: 1440px) {
  .formGroup input {
    width: 95%; /* Full width input fields */
    padding: 4%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 2%;
    height: 50px;
  }
  .formGroup select {
    width: 95%; /* Full width input fields */
    padding: 4%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 2%;
    height: 50px;
    background-color: white;
  }
}
@media screen and (max-width: 916px) {
  .editBtn {
    width: 30%;
  }
  .head1 {
    font-size: 1rem;
  }
  .head2 {
    font-size: 0.85rem;
  }
  .profileImg {
    width: 15%;
  }
  .profileForm {
    display: flex;
    flex-wrap: nowrap;
    gap: 20%;
    padding: 2% 5%;
    padding-top: 5%;
    flex-direction: column;
  }
  .formGroup {
    margin-bottom: 5%;
  }
  .formGroup p {
    padding: 3.5%;
    font-size: 1.3rem;
  }
  .logoutBtn {
    background: #f4f0f0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 1%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 50%;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    gap: 5%;
    justify-content: center;
    color: #53555a;
    font-weight: 500;
    font-size: 1.35rem;
  }

  .first {
    height: 10%;
  }
  .formGroup input {
    width: 95%; /* Full width input fields */
    padding: 4%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 2%;
    height: 50px;
  }
  .formGroup select {
    width: 95%; /* Full width input fields */
    padding: 4%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 2%;
    height: 50px;
    background-color: white;
  }
  .buttonlast {
    width: 100%;
    /* text-align: left; */
    display: flex;
    justify-content: flex-end;
  }
  /* Footer css ................................................................*/
  .footer {
    display: block;
    margin-top: 5%;
  }
  .footcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* max-width: 1200px; */
    margin: 0%;
    flex-direction: column;
    width: 100%;
  }
  .leftSection {
    min-width: 100%;
    max-width: 100%;
    margin-right: 0px;
  }
  .middleSection {
    min-width: 100%;
    max-width: 100%;
    height: 100px;
  }
  .copyr {
    display: none;
  }
  .rightSection {
    min-width: 100%;
  }
  .navLinks {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 35px;
  }

  .navLinks li {
    margin-bottom: 15%;
  }

  .navLinks a {
    color: #fff;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 39.114px;
  }
  .subscribeButton {
    font-size: 5vw;
  }
  .mobilecopyr {
    display: block;
    margin-top: 5%;
    font-size: 14px;
    color: #b3b3b3;
  }
}
@media screen and (max-width: 768px) {
  .editBtn {
    width: 30%;
  }
  .head1 {
    font-size: 1rem;
  }
  .head2 {
    font-size: 0.85rem;
  }
  .profileImg {
    width: 15%;
  }
  .profileForm {
    display: flex;
    flex-wrap: nowrap;
    gap: 20%;
    padding: 2% 5%;
    padding-top: 5%;
    flex-direction: column;
  }
  .formGroup {
    margin-bottom: 5%;
  }
  .formGroup p {
    padding: 3.5%;
    font-size: 1.3rem;
    height: 45px;
  }
  .logoutBtn {
    background: #f4f0f0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 1%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 50%;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    gap: 5%;
    justify-content: center;
    color: #53555a;
    font-weight: 500;
    font-size: 1.35rem;
  }

  .first {
    height: 10%;
  }
  .formGroup input {
    width: 95%; /* Full width input fields */
    padding: 4%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 2%;
  }
  .formGroup select {
    width: 95%; /* Full width input fields */
    padding: 4%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 2%;
  }
  .buttonlast {
    width: 100%;
    /* text-align: left; */
    display: flex;
    justify-content: flex-end;
  }
  /* Footer css ................................................................*/
  .footer {
    display: block;
    margin-top: 5%;
  }
  .footcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* max-width: 1200px; */
    margin: 0%;
    flex-direction: column;
    width: 100%;
  }
  .leftSection {
    min-width: 100%;
    max-width: 100%;
    margin-right: 0px;
  }
  .middleSection {
    min-width: 100%;
    max-width: 100%;
    height: 100px;
  }
  .copyr {
    display: none;
  }
  .rightSection {
    min-width: 100%;
  }
  .navLinks {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 35px;
  }

  .navLinks li {
    margin-bottom: 15%;
  }

  .navLinks a {
    color: #fff;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 39.114px;
  }
  .subscribeButton {
    font-size: 5vw;
  }
  .mobilecopyr {
    display: block;
    margin-top: 5%;
    font-size: 14px;
    color: #b3b3b3;
  }
}