.outer{
    display: flex;
    flex-direction: column;
}
.container{
    display: flex;
    flex-direction: row;
}
.imageSection{
    display: flex;
    width: 40%;
    background-color: #333333;

}
.client{
    width:100%
}
/* .formContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
} */
.formContainer {
  background-color: #c6c3c3;
  padding: 0% 8%;
  width: 60%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  }
  
  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333333;
  }
  
  .subtitle {
    margin-bottom: 20px;
    text-align: left;
    color: #666;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 27px */
letter-spacing: -0.18px;
  }
  
  .form {

    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin-bottom: 2%;
    border-radius: 5px;
    border: 1px solid #cccccc;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .textarea {
    width: 100%;
    padding: 10px;
    height: 12vw;
    margin-bottom: 2%;
    border-radius: 5px;
    border: 1px solid #cccccc;
    font-size: 16px;
    box-sizing: border-box;
  }
  .container2{
    border-radius: 12px;
    border: 1px solid rgba(102, 102, 102, 0.64);
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  }
  .container2 {
    margin: 2% 3%;
    padding: 50px;
    background-color: #f9f9f9; /* Adjust background color as needed */
    border-radius: 8px;
  }
  .button {
    background-color: #1F1F60; /* Dark blue background */
    color: #ffffff;
    padding: 12px 0;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #141440;
  }
  .head1{
    color: var(--Black, #000);
font-family: Inter;
font-size: 40px;
font-style: normal;
font-weight: 550;
line-height: normal;
  }
  .head2{
    color: var(--Black, #000);
font-family: Inter;
font-size: 28px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 2%;
  }
.head3{
    color: #666;
font-family: Inter;
font-size: 26px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 1%;

}
.contactItem {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 2%;
  }
  
  .icon {
    color: #007BFF; /* Adjust the color of icons */
    font-size: 1.2rem;
  }
  
  .location{
    margin: 0;
    color: var(--Black, #000);
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 550;
line-height: normal;
  }
  /* footer css starting ................................. */
  .footer {
    background-color: #1e1f26;
    color: #ffffff;
    padding: 40px 20px;
    margin-top: 3%;
    font-family: Arial, sans-serif;
  }
  
  .footcontainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    /* max-width: 1200px; */
    margin: 0 5%;
  }
  
  .leftSection {
    flex: 1;
    min-width: 25%;
    max-width: 30%;
    margin-right: 20px;
  }
  
  .logo {
    width: 150px;
    margin-bottom: 20px;
  }
  
  .description {
    font-size: 14px;
    line-height: 1.6;
    text-align: left;
    
  }
  .description2 {
    text-align: left;
    margin-top: 15px;
    letter-spacing: 0.1vw;
  }
  .textdiv{
    display: flex;
    justify-content: flex-start;
    border: 1 px solid black;
  }
  .socialIcons {
    margin-top: 15px;
    display: flex;
  }
  
  .icon img {
    width: 24px;
    margin-right: 10px;
  }
  
  .middleSection {
    flex: 1;
  
    min-width: 25%;
    max-width: 30%;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 300px;
  
    /* margin-top: 10%; */
  }
  
  .navLinks{
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 50px;
  }
  
  .navLinks li {
    margin-bottom: 25%;
  }
  
  .navLinks a {
    color: #fff;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 39.114px;
  }
  
  .navLinks a:hover {
    text-decoration: underline;
  }
  
  .rightSection {
    min-width: 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #e69500; */
  }
  
  .subscribeTitle {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .subscribeForm {
    display: flex;
    flex-direction: column;
  }
  
  .emailInput {
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .subscribeButton {
    padding: 10px;
    /* background-color: #ffa500; */
    color: #ffffff;
    border: 1.5px solid white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2vw;
  }
  
  .subscribeButton:hover {
    background-color: #e69500;
  }
  
  .copyr {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #b3b3b3;
  }
  .mobilecopyr {
    display: none;
  }
  @media screen and (max-width: 1440px) {

  .navLinks a {
    color: #fff;
    font-family: Inter;
    font-size: 18px;
  }
}
  @media screen and (max-width: 916px) {
    .container{
      flex-direction: column;
      height: auto;
    }
    .imageSection{
      display: flex;
      width: 100%;
      background-color: #333333;
      height:50vh;
  
  }
  
  .formContainer {
      background-color: #c6c3c3;
      border-radius: 10px;
      padding: 10% 10%;
      width: 100%;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      text-align: center;
    }
    .container2 {
      margin: 7%;
     padding:6%
    }
    .head1{
      color: var(--Black, #000);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 550;
  line-height: normal;
    }
    .head2{
      color: var(--Black, #000);
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5%;
    }
  .head3{
      color: #666;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 3%;
  
  }
  .location{
    margin: 0;
    color: var(--Black, #000);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 550;
line-height: normal;
  }
  .contactItem {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 7%;
  }
    
    /* footer css ..................*/
    .footer {
        margin-top: 5%;
      }
      .footcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        /* max-width: 1200px; */
        margin: 0%;
        flex-direction: column;
        width: 100%;
      }
      .leftSection {
        min-width: 80%;
        max-width: 80%;
        margin-right: 0px;
      }
      .middleSection {
        min-width: 80%;
        max-width: 80%;
        height: 100px;
      }
      .copyr {
        display: none;
      }
      .rightSection {
        min-width: 80%;
        text-align: left;
        align-items: flex-start;
      }
      .navLinks {
        list-style-type: none;
        padding: 0;
        display: flex;
        gap: 35px;
      }
    
      .navLinks li {
        margin-bottom: 15%;
      }
    
      .navLinks a {
        color: #fff;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 39.114px;
      }
      .subscribeButton {
        font-size: 3vw;
        width: 60%;
      }
      .mobilecopyr {
        display: block;
        margin-top: 5%;
        font-size: 14px;
        color: #b3b3b3;
      }
      .subscribeTitle {
        font-size: 16px;
        margin-bottom: 10px;
      }
    
      .subscribeForm {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    
      .emailInput {
        padding: 10px;
        border: none;
        border-radius: 5px;
        margin-bottom: 10px;
      }
      /* footercss............................ */
  }
  @media screen and (max-width: 768px) {

    .container{
      flex-direction: column;
      height: auto;
    }
    .imageSection{
      display: flex;
      width: 100%;
      background-color: #333333;
  
  }
  
  .formContainer {
      background-color: #c6c3c3;
      border-radius: 10px;
      padding: 10% 10%;
      width: 100%;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      text-align: center;
    }
    .container2 {
      margin: 7%;
     padding:6%
    }
    .head1{
      color: var(--Black, #000);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 550;
  line-height: normal;
    }
    .head2{
      color: var(--Black, #000);
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5%;
    }
  .head3{
      color: #666;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 3%;
  
  }
  .location{
    margin: 0;
    color: var(--Black, #000);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 550;
line-height: normal;
  }
  .contactItem {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 7%;
  }
    
    /* Footer design  ..................................*/
    .footer {
        margin-top: 5%;
      }
      .footcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        /* max-width: 1200px; */
        margin: 0%;
        flex-direction: column;
        width: 100%;
      }
      .leftSection {
        min-width: 100%;
        max-width: 100%;
        margin-right: 0px;
      }
      .middleSection {
        min-width: 100%;
        max-width: 100%;
        height: 100px;
      }
      .copyr {
        display: none;
      }
      .rightSection {
        min-width: 100%;
      }
      .navLinks {
        list-style-type: none;
        padding: 0;
        display: flex;
        gap: 35px;
      }
    
      .navLinks li {
        margin-bottom: 15%;
      }
    
      .navLinks a {
        color: #fff;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 39.114px;
      }
      .subscribeButton {
        font-size: 5vw;
      }
      .mobilecopyr {
        display: block;
        margin-top: 5%;
        font-size: 14px;
        color: #b3b3b3;
      }
  }